import { extractValueFromMainTitle } from "./string";
import { CSBranchName, ProductAffiliation } from 'types/PageType.enum';

const mainTag = "<main>";
export const getComponentConfigs = (components: any, publicConfigurations: any) => {
  if (!components) return {
    gaPageData: {},
    gaProductMetadata: {}
  };
  let gaPageData: any = {};
  let gaProductMetadata: any = {};
  let isMarketingCarouselBanner = false;
  let isShowLastBreadcrumbs = true;

  const hasMainTitle = components?.layout?.some(
    (layout: any) => {
      let title = layout?.dataComponent?.title;
      if (layout?.dataComponent?.block_title?.includes(mainTag)) {
        title = layout?.dataComponent?.block_title;
      }
      const { isMainTitle } = extractValueFromMainTitle(title);
      return isMainTitle;
    }
  );

  const carouselBannerIdx = components?.layout?.findIndex(
    (layout: any) => {
      return layout?.nameComponent === "MarketingCarouselBanner";
    }
  );

  if (carouselBannerIdx >= 0 ) {
    isMarketingCarouselBanner = true;
    const titleCarouselRefTabs = components?.layout[carouselBannerIdx].dataComponent?.reference?.[0]?.reference_tabs?.[0]?.display_title;
    if (!hasMainTitle && components?.layout?.length > carouselBannerIdx && !titleCarouselRefTabs) {
      const layoutItem = components?.layout?.find(layout => (components?.layout?.[carouselBannerIdx + 1]?.nameComponent === layout?.nameComponent
        && ["InsuranceRecommendedProducts", "ProductCategoryBlock", "CSOneWebContentBlock", "CSCardList"].indexOf(layout?.nameComponent) >= 0))
      if (layoutItem) {
        if (layoutItem?.dataComponent?.title) {
          layoutItem.dataComponent.title = `${mainTag}${layoutItem.dataComponent.title}${mainTag}`;
        } else if (layoutItem?.dataComponent?.reference && layoutItem?.dataComponent?.reference.length > 0 && !layoutItem.dataComponent.reference[0].title.includes(mainTag)) {
          layoutItem.dataComponent.reference[0].title = `${mainTag}${layoutItem.dataComponent.reference[0].title}${mainTag}`;
        }
      }
    }
  }

  const NextHead = components?.layout?.find(
    (layout: any) => {
      return layout?.nameComponent === "NextHead";
    }
  );

  if (NextHead && NextHead.dataComponent) {
    let product_affiliation: string = "";
    if (publicConfigurations?.csBranchName === CSBranchName.Capricorn) {
      product_affiliation = components.title.includes(ProductAffiliation.InsuranceKey)
        ? ProductAffiliation.Insurance : components.title.includes(ProductAffiliation.TakafulKey)
          ? ProductAffiliation.Takaful : "";

      if (!product_affiliation) {
        if (typeof window !== "undefined") {
          const elementId = window.location.hash.substring(1);
          if (elementId) {
            product_affiliation = elementId.toLowerCase().includes(ProductAffiliation.Insurance) ? ProductAffiliation.Insurance :
              elementId.toLowerCase().includes(ProductAffiliation.Takaful) ? ProductAffiliation.Takaful : ProductAffiliation.CombineKey;
          } else {
            product_affiliation = ProductAffiliation.CombineKey;
          }
        }
      }
    }

    gaPageData = {
      content_type: NextHead.dataComponent.pageType,
      content_title: NextHead.dataComponent.seo.meta_title || "",
      product_affiliation: product_affiliation
    }

    if (NextHead.dataComponent.body) {
      const product_metadataItem = NextHead.dataComponent.body.find(cp => !!cp.product_metadata);
      const top_bannerItem = NextHead.dataComponent.body.find(cp => !!cp.top_banner);
      if (top_bannerItem && product_metadataItem?.product_metadata?._metadata?.uid) {
        gaProductMetadata = {
          item_name: top_bannerItem.top_banner.reference?.[0]?.h1_title || "",
          item_id: product_metadataItem ? product_metadataItem.product_metadata?._metadata?.uid : "",
          item_brand: 'fwd',
          item_category: product_metadataItem ? product_metadataItem.product_metadata?.insurance_category?.join(", ") : "",
          affiliation: product_affiliation,
          item_list_name: NextHead.dataComponent.pageType
        };
      }
    }

    if (NextHead.dataComponent.body) {
      const isLimitBreadcrumbs = NextHead.dataComponent.body.find(
        (block: any) => !!block.article_block || !!block.product_metadata
      );
      if (isLimitBreadcrumbs) {
        isShowLastBreadcrumbs = false;
      }
    }

  }

  return {
    hasMainTitle,
    isMarketingCarouselBanner,
    isShowLastBreadcrumbs,
    gaPageData: gaPageData,
    gaProductMetadata: gaProductMetadata
  };
};

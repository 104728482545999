import styled from "styled-components";
import MainLayout from "components/MainLayout";
import { colorsUtils, breakpoint, Text } from "@d2c-ui-components-react";
import { CSHeader, CSFooter, OmneHeader, OmneFooter } from "@components";
import Head from "next/head";
import {
  SearchInput as NextGenSearchInput,
  Header,
} from "@fwd-dep/nextgen-ui-lib";
import { useRouter } from "next/router";
import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { resolveAbsoluteUrl, resolveAbsoluteUrlInParagraph } from "utils/route";
import I18nContext from "i18n/context/LanguageContext";
import { ErrorPages } from "types/ErrorPage.interface";
import { EnquiryProvider } from "context/EnquiryContext";
import gtmUtils from "utils/gtm";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import DropdownIcon from "../../public/dropdown.svg";
import { Box, MenuItem, Select } from "@material-ui/core";
import { Button } from "@d2c-ui-components-react";
import { getWindowHref } from "@utils";
import { ModalPageNotFoundProvider } from "context/ModalPageNotFoundContext";
import DOMPurify from "isomorphic-dompurify";
import { getOmneFooterData } from "components/OmneFooter/services/index.service";
import { getOmneHeaderData } from "components/OmneHeader/services/index.service";
import { OMNE_CONTENT_TYPE_KEY } from "constants/contentTypeTemplates";

const Container = styled.div`
  padding: 10rem 1rem 4rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  h1 {
    color: ${colorsUtils.darkGreen};
    font-size: 1.5625rem;
    font-weight: 700;
    line-height: 1.9375rem;
    letter-spacing: 0.00735em;
  }

  p,
  .description {
    font-weight: 300;
    font-size: 1rem;
    color: ${colorsUtils.darkGreen};
    line-height: 1.5rem;
    margin-top: 8px;
    margin-bottom: 0;
  }

  .error {
    font-weight: 300;
    font-size: 13px;
    color: #666666;
    line-height: 20px;
    margin-top: 8px;
  }
`;

const Wrapper = styled.div<{ mt?: string }>`
  text-align: center;
  margin-top: ${({ mt }) => mt ?? 0};
  a:hover {
    color: ${colorsUtils.darkGreen};
  }
  h1 {
    font-size: 25px;
    color: ${colorsUtils.darkGreen};
    line-height: 31px;
    font-weight: 700;
  }
`;

const TabsContainer = styled.div`
  width: 100%;
  margin-top: 9rem;
  .MuiTabs-root {
    border-bottom: 1px solid #dbdfe1;
    min-width: auto !important;
    min-height: auto;
    margin: 1.5rem 0;
  }
  .MuiTabs-indicator {
    background-color: ${colorsUtils.fwdOrange};
    height: 4px;
  }
  .Mui-selected .MuiTab-wrapper {
    color: ${colorsUtils.fwdOrange};
  }
  .MuiTab-wrapper {
    text-transform: capitalize;
    font-size: 1rem;
    line-height: 1.5rem;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1rem;
  padding-bottom: 4rem;

  > div {
    flex: 1;
    max-width: 500px;
    .clearInput {
      background-color: #8b8e8f;
      color: ${colorsUtils.fwdWhite};
    }
    .MuiInputBase-root input {
      color: ${colorsUtils.grey2};
      caret-color: ${colorsUtils.fwdOrange};
      font-weight: 700;
    }
  }

  ${breakpoint("md")`
    padding: 0;
    padding-bottom: 8rem;
  `}
`;

const HeaderWrapper = styled.div`
  #header {
    position: fixed !important;
    top: 0 !important;
  }
`;

const MainLayoutWrapper = styled.div`
  flex: 1;
`;

interface ErrorComponentProps {
  data: ErrorPages;
  errorCode?: number;
  locale?: any;
  numberOfLang?: number;
}

function ErrorComponent({
  data,
  errorCode = 404,
  locale,
  numberOfLang,
}: ErrorComponentProps): JSX.Element {
  const router = useRouter();
  const [tab, setTab] = useState<number>(0);
  const [loaded, setLoaded] = useState<boolean>(false);
  const i18nContext = useContext(I18nContext);
  const bodyData = data?.body?.[0];
  const headerData = data?.header?.[0];
  const footerData = data?.footer?.[0];
  const [selectedMarket, setSelectedMarket] = useState("");
  const [selectedEnvironment, setSelectedEnvironment] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  useEffect(() => {
    if (loaded && typeof window !== "undefined") {
      gtmUtils.pushEvent({
        event: "error",
        url: getWindowHref(),
        errorCode: `${errorCode}`,
      });
    }
  }, [loaded]);

  const onChangeTab = useCallback(
    (_: React.ChangeEvent<{}>, newValue: number) => {
      setTab(newValue);
    },
    []
  );

  const onSearch = useCallback(
    (searchText: string) => {
      searchText &&
        router.push({
          pathname: resolveAbsoluteUrl(
            bodyData?.error_404?.search_url || "/search",
            i18nContext
          ),
          query: {
            q: searchText,
          },
        });
    },
    [router, bodyData?.error_404?.search_url, i18nContext]
  );

  const handleChangeSelectedMarket = (
    event: ChangeEvent<{ name?: string | undefined; value: any }>
  ) => {
    const market = event.target.value;
    setSelectedMarket(market);
    const environment = bodyData?.error_400?.live_preview_options?.find(
      (i) => i.market === market
    )?.environments?.[0];
    if (environment) {
      setSelectedEnvironment(environment);
    } else {
      setSelectedEnvironment("");
    }
  };

  const handleChangeEnvironment = (
    event: ChangeEvent<{ name?: string | undefined; value: any }>
  ) => {
    const environment = event.target.value;
    setSelectedEnvironment(environment);
  };

  const handleSubmit = () => {
    if (selectedMarket && selectedEnvironment) {
      setLoading(true);
      router.push(`/?market=${selectedMarket}&env=${selectedEnvironment}`);
    }
  };

  const render404 = () => (
    <EnquiryProvider value={footerData} isErrorPage>
      <ModalPageNotFoundProvider>
        <Head>
          <title>
            {bodyData?.error_404?.page_title ?? "Sorry - page not found"}
          </title>
          <meta
            key="og:title"
            property="og:title"
            content={
              bodyData?.error_404?.meta_title || "Sorry - page not found"
            }
          />
        </Head>

        {headerData && (
          <>
            {headerData._content_type_uid?.includes(OMNE_CONTENT_TYPE_KEY) ? (
              <OmneHeader
                data={getOmneHeaderData(
                  headerData,
                  locale?.split("-")[0] || "",
                  numberOfLang || 1
                )}
              />
            ) : (
              <CSHeader data={headerData} />
            )}
          </>
        )}

        <MainLayoutWrapper>
          <MainLayout maxWidth="1288px">
            <Container>
              <Wrapper>
                <h1>
                  {bodyData?.error_404?.title ?? "Sorry - page not found"}
                </h1>
                <div
                  className="description"
                  dangerouslySetInnerHTML={{
                    __html: resolveAbsoluteUrlInParagraph(
                      bodyData?.error_404?.description,
                      i18nContext
                    ),
                  }}
                />
                <div className="error">Error 404</div>
              </Wrapper>
            </Container>
            {!bodyData?.error_404?.hide_search_box && (
              <SearchContainer>
                <NextGenSearchInput
                  placeholderText={
                    bodyData?.error_404?.search_placeholder_text ??
                    "Please enter a keyword or phrase"
                  }
                  onSubmit={onSearch}
                  pastSearchRecordsCount="8"
                  color="grey"
                  bgColor="white"
                  removeBtnLabel="remove"
                />
              </SearchContainer>
            )}
          </MainLayout>
        </MainLayoutWrapper>

        {footerData && (
          <>
            {footerData._content_type_uid?.includes(OMNE_CONTENT_TYPE_KEY) ? (
              <OmneFooter
                data={getOmneFooterData(
                  footerData,
                  locale?.split("-")[0] || "",
                  numberOfLang || 1
                )}
              />
            ) : (
              <CSFooter data={footerData} />
            )}
          </>
        )}
      </ModalPageNotFoundProvider>
    </EnquiryProvider>
  );

  const render400 = () => (
    <EnquiryProvider value={footerData} isErrorPage>
      <ModalPageNotFoundProvider>
        <Head>
          <title>
            {bodyData?.error_400?.page_title ?? "Sorry - page not found"}
          </title>
        </Head>
        {headerData && <CSHeader data={headerData} />}
        <MainLayout maxWidth="1288px">
          <Container>
            <Wrapper>
              <h1>{bodyData?.error_400?.title ?? "Sorry - page not found"}</h1>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: resolveAbsoluteUrlInParagraph(
                    bodyData?.error_400?.description,
                    i18nContext
                  ),
                }}
              />
              <div className="error">Error 400</div>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gridGap: 30,
                  marginTop: 30,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gridGap: 10,
                  }}
                >
                  <Text>{bodyData?.error_400?.market_title}</Text>
                  <Select
                    labelId="market"
                    IconComponent={DropdownIcon}
                    MenuProps={{
                      classes: {
                        paper: "mui-dropdown-menu",
                      },
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                    value={selectedMarket}
                    onChange={handleChangeSelectedMarket}
                    disabled={loading}
                  >
                    {bodyData?.error_400?.live_preview_options.map(
                      (options, index) => (
                        <MenuItem key={index} value={options.market}>
                          {options.market}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gridGap: 10,
                  }}
                >
                  <Text>{bodyData?.error_400?.environment_title}</Text>
                  <Select
                    labelId="environment"
                    IconComponent={DropdownIcon}
                    MenuProps={{
                      classes: {
                        paper: "mui-dropdown-menu",
                      },
                      getContentAnchorEl: null,
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                    }}
                    value={selectedEnvironment}
                    onChange={handleChangeEnvironment}
                    disabled={!selectedMarket || loading}
                  >
                    {bodyData?.error_400?.live_preview_options
                      ?.find((i) => i.market === selectedMarket)
                      ?.environments?.map((environment, index) => (
                        <MenuItem key={index} value={environment}>
                          {environment}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
              </Box>
              <Box
                sx={{
                  maxWidth: 200,
                  display: "flex",
                  justifyContent: "center",
                  margin: "0 auto",
                  marginTop: 20,
                }}
              >
                <Button
                  onClick={handleSubmit}
                  disabled={!selectedMarket || !selectedEnvironment || loading}
                >
                  {bodyData?.error_400?.cta_title}
                </Button>
              </Box>
            </Wrapper>
          </Container>
        </MainLayout>

        {footerData && <CSFooter data={footerData} />}
      </ModalPageNotFoundProvider>
    </EnquiryProvider>
  );

  if (errorCode === 404) {
    return render404();
  }
  if (errorCode === 400) {
    return render400();
  }

  return (
    <ModalPageNotFoundProvider>
      <Head>
        <title>{bodyData?.error_500?.page_title ?? "FWD"}</title>
      </Head>
      <HeaderWrapper>
        <Header setHeaderTransitionStatus={() => {}} />
      </HeaderWrapper>
      <MainLayout maxWidth="1288px">
        {bodyData?.error_500?.language?.length ? (
          <>
            <TabsContainer>
              <Tabs
                value={tab}
                onChange={onChangeTab}
                scrollButtons="auto"
                centered
              >
                {bodyData?.error_500.language.map((item, i) => (
                  <Tab key={i} label={item.language_code} disableRipple />
                ))}
              </Tabs>
            </TabsContainer>
            <Wrapper mt="4rem">
              <h1>
                {bodyData?.error_500.language[tab]?.title ??
                  "Sorry - page not found"}
              </h1>
              <div
                className="description"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    resolveAbsoluteUrlInParagraph(
                      bodyData?.error_500.language[tab]?.description,
                      i18nContext
                    )
                  ),
                }}
              />
            </Wrapper>
          </>
        ) : null}
      </MainLayout>
    </ModalPageNotFoundProvider>
  );
}

export default ErrorComponent;

import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  ReactNode,
} from "react";

interface BaseStorageContextProps {
  dataStorage: Record<string, any>;
  setDataStorage: (data: Record<string, any>) => void;
  handleUpdateDataStorage: (data: Record<string, any>) => void;
  clearDataStorage: () => void;
}

interface BaseStorageProviderProps {
  children: ReactNode;
  storageKey: string;
  timeoutDuration?: number;
}

const BaseStorageContext = createContext<BaseStorageContextProps>({
  dataStorage: {},
  setDataStorage: () => {},
  handleUpdateDataStorage: () => {},
  clearDataStorage: () => {},
});

export const BaseStorageProvider: React.FC<BaseStorageProviderProps> = ({
  children,
  storageKey,
  timeoutDuration = 60000,
}) => {
  const [dataStorage, setDataStorage] = useState<Record<string, any>>({});

  // Load data from sessionStorage on the client side
  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedData = sessionStorage.getItem(storageKey);
      setDataStorage(storedData ? JSON.parse(storedData) : {});
    }
  }, [storageKey]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem(storageKey, JSON.stringify(dataStorage));
    }
  }, [dataStorage, storageKey]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setDataStorage({});
      if (typeof window !== "undefined") {
        sessionStorage.removeItem(storageKey);
      }
    }, timeoutDuration);

    return () => clearTimeout(timeoutId);
  }, [timeoutDuration, storageKey]);

  const clearDataStorage = useCallback(() => {
    setDataStorage({});
    if (typeof window !== "undefined") {
      sessionStorage.removeItem(storageKey);
    }
  }, [storageKey]);

  const handleUpdateDataStorage = useCallback(
    (newData: Record<string, any>) => {
      setDataStorage((prevData) => ({ ...prevData, ...newData }));
    },
    []
  );

  return (
    <BaseStorageContext.Provider
      value={{
        dataStorage,
        setDataStorage,
        clearDataStorage,
        handleUpdateDataStorage,
      }}
    >
      {children}
    </BaseStorageContext.Provider>
  );
};

export const useBaseStorageContext = () => useContext(BaseStorageContext);

export default BaseStorageContext;

import { useResponsive } from "@d2c-ui-components-react";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import {
  getWindow,
  getWindowOrigin,
  replaceContentStackURL,
  setWindowHref,
} from "@utils";
import I18nContext, { I18nContextData } from "i18n/context/LanguageContext";
import isEmpty from "lodash/isEmpty";
import { parseCookies, setCookie, destroyCookie } from "nookies";
import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import gtmUtils, { gaEventAction } from "utils/gtm";
import { resolveAbsoluteUrl } from "utils/route";

import { Button } from "@fwd-dep/nextgen-ui-lib";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import moment from "moment";

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    justify-content: center;
  }
  .MuiDialog-paperFullScreen {
    background: transparent;
  }
  .MuiGrid-item {
    @media only screen and (min-width: 600px) {
      padding: 0 3.0625rem;
    }

    @media only screen and (min-width: 960px) {
      flex-grow: 0;
      max-width: 91.666667%;
      flex-basis: 91.666667%;
    }
  }

  .img-container {
    width: calc((100vh - 96px) / 3 * 2);
    margin: 0 auto;
    position: relative;
    max-width: 100%;

    @media only screen and (min-width: 960px) {
      width: calc((100vh - 112px) / 9 * 16);
    }

    .close-container {
      background-color: #ffffff;
      top: -20px;
      right: -20px;
      width: 40px;
      cursor: pointer;
      height: 40px;
      display: flex;
      z-index: 1;
      position: absolute;
      align-items: center;
      border-radius: 50%;
      justify-content: center;

      svg {
        width: 24px;
        height: 24px;
      }

      @media only screen and (max-width: 719.95px) {
        top: -16px;
        right: -16px;
        width: 32px;
        height: 32px;
      }
    }

    picture {
      display: block;
      position: relative;

      img {
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 16px;
      }

      @media only screen and (max-width: 960px) {
        padding-top: 150%;
      }

      @media only screen and (min-width: 960px) {
        padding-top: 56.25%;
      }
    }
  }

  @media (max-width: 959.95px) {
    .root-container {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  .MuiContainer-maxWidthXl {
    max-width: 1288px;
  }
`;

const Container = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

const CTAContainer = styled.div`
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
  mstransform: translateX(-50%);
`;

export interface SplashData {
  desktop_image: {
    url: string;
  };
  mobile_image: {
    url: string;
  };
  target_urls: string;
  url: string;
  cta: {
    title: string;
    url: string;
  };
  schedule: {
    start_date_time: string; // e.g. 2022-12-27T13:40:00.000Z
    end_date_time: string;
  };
  always_show: boolean;
}

interface Props {
  data: SplashData;
}

const COOKIE_DATA = {
  key: "splash",
  value: "true",
};

export const Splash = (props: Props) => {
  const { data } = props;
  const { desktop_image, mobile_image, target_urls, url, cta, schedule } =
    data || {};

  const isDesktop960 = useResponsive({ breakpoint: "lg", offset: -30 });
  const i18nContext = useContext(I18nContext);
  const [open, setOpen] = React.useState(false);
  const cookies = parseCookies();
  const isShownSplash = cookies[COOKIE_DATA.key];
  const [timeoutId, setTimeoutId] = React.useState<any>(null);
  const cookieData = JSON.parse(cookies[COOKIE_DATA.key] || "{}");
  const shouldShow = useCallback(() => {
    if (timeoutId) return false;
    const startDate = moment.utc(schedule?.start_date_time);
    const endDate = moment.utc(schedule?.end_date_time);
    const lastStartDate = moment.utc(cookieData.startDate || "");
    const lastEndDate = moment.utc(cookieData.endDate || "");
    const isNewVersion =
      lastStartDate.diff(startDate) !== 0 || lastEndDate.diff(endDate) !== 0;
    const resp =
      !(
        isShownSplash &&
        cookieData.language === i18nContext.language.languageCode &&
        cookieData.country === i18nContext.countryCode
      ) || isNewVersion;

    return resp;
  }, [
    cookieData.country,
    cookieData.endDate,
    cookieData.language,
    cookieData.startDate,
    i18nContext.countryCode,
    i18nContext.language.languageCode,
    isShownSplash,
    schedule?.end_date_time,
    schedule?.start_date_time,
    timeoutId,
  ]);

  React.useEffect(() => {
    if (
      !(
        cookieData.language === i18nContext.language.languageCode &&
        cookieData.country === i18nContext.countryCode
      )
    ) {
      destroyCookie(undefined, COOKIE_DATA.key, { path: "/" });
    }
  }, [
    cookieData.country,
    cookieData.language,
    i18nContext.countryCode,
    i18nContext.language.languageCode,
  ]);
  const hideSplashInNextTime = useCallback(() => {
    setCookie(
      null,
      COOKIE_DATA.key,
      JSON.stringify({
        startDate: schedule?.start_date_time || "",
        endDate: schedule?.end_date_time || "",
        language: i18nContext.language.languageCode,
        country: i18nContext.countryCode,
      }),
      {
        maxAge: 30 * 24 * 60 * 60,
        path: "/",
      }
    );
  }, [
    i18nContext.countryCode,
    i18nContext.language.languageCode,
    schedule?.end_date_time,
    schedule?.start_date_time,
  ]);
  const onBtnCloseClicked = useCallback(() => {
    setOpen(false);

    hideSplashInNextTime();
  }, [hideSplashInNextTime]);

  const onImageClicked = () => {
    hideSplashInNextTime();
    setWindowHref(resolveAbsoluteUrl(url, i18nContext));
  };
  const onButtonCTAClicked = (title: string = "", url: string = "") => {
    hideSplashInNextTime();

    gtmUtils.buttonClick({
      buttonTitle: title,
      destinationUrl: getWindowOrigin() + url,
      componentName: "Splash",
    });
    gtmUtils.navigation({
      event_action: gaEventAction.button_click,
      button_title: title,
      destination_url: getWindowOrigin() + url,
      component_name: "Splash",
      product_affiliation:
        i18nContext.componentConfigs?.gaPageData?.product_affiliation || "",
    });
  };
  const scheduleShowHideSplash = useCallback(() => {
    if (timeoutId) return;
    const now = moment.utc();
    const startDate = moment.utc(schedule?.start_date_time);
    const endDate = moment.utc(schedule?.end_date_time);
    const lastStartDate = moment.utc(cookieData.startDate || "");
    const lastEndDate = moment.utc(cookieData.endDate || "");
    const diffNowAndStartDateInSeconds = startDate.diff(now);
    const diffNowAndEndDateInSeconds = endDate.diff(now);

    let shouldShow = true;

    if (schedule?.start_date_time && diffNowAndStartDateInSeconds > 0) {
      shouldShow = false;
    }

    if (schedule?.end_date_time) {
      if (diffNowAndEndDateInSeconds < 0) {
        shouldShow = false;
      }
    }

    if (shouldShow) {
      return setTimeout(() => {
        setOpen(true);
      }, 100);
    } else {
      onBtnCloseClicked();
    }
  }, [
    timeoutId,
    schedule?.start_date_time,
    schedule?.end_date_time,
    cookieData.startDate,
    cookieData.endDate,
    onBtnCloseClicked,
  ]);
  const isCurrentUrlMatch = useCallback(() => {
    const targetUrls = target_urls ? target_urls.split(",") : [];
    const currentUrl =
      getWindow()?.location?.pathname?.replace(
        `/${i18nContext?.language?.languageCode}`,
        ""
      ) || "";
    const match = targetUrls.some((url) => {
      if (url === "/*") {
        return true;
      }
      if (url === "/") {
        return currentUrl === "/";
      } else if (new RegExp(url).test(currentUrl)) {
        return true;
      }
      return false;
    });
    return match;
  }, [i18nContext?.language?.languageCode, target_urls]);

  React.useEffect(() => {
    if (!isCurrentUrlMatch()) return;
    if (!shouldShow()) {
      return;
    }

    setOpen(true);
    const newTimeoutId = scheduleShowHideSplash();
    if (newTimeoutId) {
      clearTimeout(timeoutId);
      setTimeoutId(newTimeoutId);
    }
  }, [isCurrentUrlMatch, shouldShow, scheduleShowHideSplash, timeoutId]);

  if (isEmpty(data)) {
    return null;
  }

  return (
    <StyledDialog open={open} onClose={() => setOpen(false)} fullScreen>
      <section>
        <Container className="root-container">
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Box
                height="100%"
                display="flex"
                position="relative"
                alignItems="center"
              >
                <div className="img-container">
                  <div className="close-container" onClick={onBtnCloseClicked}>
                    <CloseRoundedIcon />
                  </div>
                  <Link href="#" onClick={onImageClicked}>
                    <picture>
                      <img
                        src={
                          isDesktop960
                            ? replaceContentStackURL(desktop_image?.url || "")
                            : replaceContentStackURL(mobile_image?.url || "")
                        }
                      />
                    </picture>
                  </Link>
                  {cta?.title && (
                    <CTAContainer>
                      <a href={resolveAbsoluteUrl(cta?.url, i18nContext)}>
                        <Button
                          color="white"
                          onClick={() =>
                            onButtonCTAClicked(
                              cta?.title,
                              resolveAbsoluteUrl(cta?.url, i18nContext)
                            )
                          }
                        >
                          {cta?.title}
                        </Button>
                      </a>
                    </CTAContainer>
                  )}
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </section>
    </StyledDialog>
  );
};

export default Splash;

import React, { useContext } from "react";
import { CookieBanner as NextgenCookieBanner } from "@fwd-dep/nextgen-ui-lib";
import { resolveAbsoluteUrlInParagraph } from "utils/route";
import I18nContext from "i18n/context/LanguageContext";

interface Props {
  data: {
    description: string;
    cta_text: string;
    expiry_days?: number;
  };
}

const CookieBanner = ({ data }: Props) => {
  const i18nContext = useContext(I18nContext);
  const props = {
    setCookieBannerExist: () => {},
    sitecoreContext: {
      siteSettings: {
        cookieMessage: resolveAbsoluteUrlInParagraph(
          data?.description,
          i18nContext
        ),
        cookieTimeStamp: data?.expiry_days ? `${data?.expiry_days}` : "",
      },
    },
    btnText: data?.cta_text ?? "",
  };

  if (typeof window !== "undefined") {
    return <NextgenCookieBanner {...props} />;
  } else {
    return <></>;
  }
};

export default CookieBanner;

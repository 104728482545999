import React, { useEffect, useState, useCallback, useContext } from "react";
import styled from "styled-components";
import DOMPurify from "isomorphic-dompurify";
import { useMediaQuery } from "@material-ui/core";
import { breakpointList, colorsUtils } from "@d2c-ui-components-react";
import { Announcement } from "types/Annoucement";
import { NamedIcon, BrandingIcon } from "@fwd-dep/nextgen-ui-lib";
import moment from "moment";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { breakpoint } from "@d2c-ui-components-react";
import { hashText } from "utils/gmtHash";
import I18nContext from "i18n/context/LanguageContext";
import { resolveAbsoluteUrlInParagraph } from "utils/route";
import { getFormatDate } from "utils/string";
import { getWindow } from "@utils";

const Container = styled.div`
  background-color: #fef9f4;
  border-bottom: 1px solid #dbdfe1;
  padding: 8px;
  padding-bottom: 16px;

  ${breakpoint("md")`
    padding: 4px 8px;
  `};
  ${breakpoint("lg")`
    padding: 12px 32px;
  `};
  ${breakpoint("xl")`
    padding: 12px 62px;
  `};
`;

const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 0 auto;
`;

const Icon = styled.div`
  flex: 0 0 24px;
  margin-right: 8px;
  margin-top: -1px;
  ${breakpoint("md")`
    margin-top: 2px;
  `};
  svg {
    color: ${colorsUtils.fwdOrange} !important;
    height: 24px !important;
    width: 24px !important;
  }
`;

const CloseButton = styled.div`
  flex: 0 0 24px;
  svg {
    width: 18px;
    height: 18px;
    &:hover {
      cursor: pointer;
      background: ${colorsUtils.fwdOrange};
      border-radius: 4px;
      color: #fff;
    }
  }
`;

const MessageList = styled.div`
  padding: 0 8px;
  width: 100%;
`;

const MessageItem = styled.div`
  display: flex;
  margin: 6px 0;
`;

const MessageDate = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  color: #183028;
  flex: 0 0 145px;
  border-right: 1px solid #dbdfe1;
  margin-right: 16px;
`;

const MessageContent = styled.div`
  padding-right: 16px;
`;

const MessageText = styled.div`
  display: inline-block;
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5rem;
  color: #183028;
  a {
    text-decoration: underline;
  }
  p {
    margin-bottom: 0;
  }
  width: 100%;
`;

const MessageCarousel = styled.div`
  width: 100%;
  width: calc(100% - 24px) !important;
  padding: 0 8px;

  .notification {
    display: flex !important;
  }
  .notification__date {
    font-size: 0.875rem;
    line-height: 18px;
    color: #183028;
    font-weight: 700;
  }
  .notification__text {
    font-size: 0.875rem;
    line-height: 18px;
    color: #183028;
    font-weight: 300;
    padding-top: 1px;
    padding-bottom: 8px;
    p {
      margin-bottom: 0;
    }
  }

  .slick-dots {
    position: absolute;
    bottom: 16px;
    height: 0;
    li {
      width: 10px;
      padding: 0;
      margin: 0 1px;
      button {
        padding: 0;
        &:before {
          position: absolute;
          top: 0;
          bottom: 0;
          font-size: 10px;
          height: 10px;
          width: 10px;
          color: #b3b6b8;
        }
      }
    }
    li.slick-active {
      button {
        &:before {
          color: ${colorsUtils.fwdOrange};
        }
      }
    }
  }
`;

const MobileContent = styled.div``;

const CLOSED = "closed";

const sliderSettings = {
  arrows: false,
  dots: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
} as any;

export interface ToastNotificationProps {
  announcements: Announcement[];
}

export const ToastNotification: React.FC<ToastNotificationProps> = (props) => {
  const isDesktop = useMediaQuery(`(min-width:${breakpointList.md}px)`);
  const [visibleAnnouncements, setVisibleAnnouncements] = useState<
    Announcement[]
  >([]);
  const [visibleClose, setVisibleClose] = useState(false);

  const i18nContext = useContext(I18nContext);
  const currentLanguage = i18nContext.language;

  const isCurrentUrlMatch = (announcement: Announcement) => {
    const window = getWindow();
    if (!window) {
      return false;
    }

    const targetUrls = announcement.target_urls
      ? announcement.target_urls.split(",")
      : [];

    const pageOrigin = window?.location?.origin;
    const pageURL = window?.location?.href?.replace(`${pageOrigin}`, "");
    const currentUrl = pageURL.replace(
        `/${currentLanguage?.languageCode}`,
        ""
      ) || "";

    const match = targetUrls.some((url) => {
      if (url === "/*") {
        return true;
      }
      if (url === "/") {
        return currentUrl === "/";
      } else if (new RegExp(url).test(currentUrl)) {
        if (url === currentUrl) {
          return true;
        }
        if (url.endsWith("/*")) {
          return true;
        }

        return false;
      }

      return false;
    });
    return match;
  };

  const isInVisibleTime = (announcement: Announcement) => {
    const now = moment();
    if (
      now.isBefore(moment(announcement.start_date)) ||
      now.isAfter(moment(announcement.end_date))
    ) {
      return false;
    }
    return true;
  };

  const isNotClosed = (announcement: Announcement) => {
    if (!announcement.display_close_button) {
      return true;
    }
    const hashed = hashText(announcement.title);
    const value = sessionStorage.getItem(hashed);
    return value !== CLOSED;
  };

  const filterVisibleAnnouncements = useCallback(() => {
    const results =
      props.announcements?.filter((announcement) => {
        return (
          isCurrentUrlMatch(announcement) &&
          isInVisibleTime(announcement) &&
          isNotClosed(announcement)
        );
      }) ?? [];
    return results.sort((a, b) =>
      moment(a.start_date).isAfter(moment(b.start_date)) ? 1 : -1
    );
  }, [currentLanguage?.languageCode, props.announcements]);

  const handleClose = () => {
    for (const announcement of visibleAnnouncements) {
      if (announcement.display_close_button) {
        sessionStorage.setItem(hashText(announcement.title), CLOSED);
      }
    }
    setVisibleClose(false);
    setVisibleAnnouncements(filterVisibleAnnouncements());
  };

  useEffect(() => {
    const newVisibleAnnouncements = filterVisibleAnnouncements();
    const visibleClose = newVisibleAnnouncements.find(
      (a) => a.display_close_button
    )
      ? true
      : false;
    setVisibleAnnouncements(filterVisibleAnnouncements());
    setVisibleClose(visibleClose);
  }, [filterVisibleAnnouncements]);

  if (visibleAnnouncements.length === 0) {
    return null;
  }

  return (
    <Container id="oneweb-notifications-container" className="notifications">
      <Wrapper>
        {isDesktop ? (
          <MessageList>
            {visibleAnnouncements.map((announcement, index) => (
              <MessageItem key={index}>
                <Icon>
                  {announcement.icon && (
                    <BrandingIcon
                      icon={
                        announcement.icon.includes("https://")
                          ? ""
                          : announcement.icon
                      }
                      iconUrl={
                        announcement.icon.includes("https://")
                          ? announcement.icon
                          : ""
                      }
                      shape="default"
                      hasHoverEffect={false}
                    />
                  )}
                </Icon>
                {announcement.display_published_date && (
                  <MessageDate>
                    {getFormatDate(
                      announcement.published_date,
                      i18nContext.language.languageCode,
                      i18nContext.countryCode
                    )}
                  </MessageDate>
                )}

                <MessageContent>
                  <MessageText
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(
                        announcement.display_title
                          ? `<strong>${
                              announcement.display_title
                            }</strong> ${resolveAbsoluteUrlInParagraph(
                              announcement.message,
                              i18nContext
                            )}`
                          : resolveAbsoluteUrlInParagraph(
                              announcement.message,
                              i18nContext
                            ),
                        {
                          ADD_ATTR: ["target"],
                        }
                      ),
                    }}
                  />
                </MessageContent>
              </MessageItem>
            ))}
          </MessageList>
        ) : (
          <MessageCarousel>
            <Slider {...sliderSettings}>
              {visibleAnnouncements.map((announcement, index) => (
                <div className="notification" key={index}>
                  {announcement.icon && (
                    <Icon>
                      <BrandingIcon
                        icon={
                          announcement.icon.includes("https://")
                            ? ""
                            : announcement.icon
                        }
                        iconUrl={
                          announcement.icon.includes("https://")
                            ? announcement.icon
                            : ""
                        }
                        shape="default"
                        hasHoverEffect={false}
                      />
                    </Icon>
                  )}
                  <MobileContent>
                    {announcement.display_published_date && (
                      <div className="notification__date">
                        {getFormatDate(
                          announcement.published_date,
                          i18nContext.language.languageCode,
                          i18nContext.countryCode
                        )}
                      </div>
                    )}
                    <div
                      className="notification__text"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          announcement.display_title
                            ? `<strong>${
                                announcement.display_title
                              }</strong> ${resolveAbsoluteUrlInParagraph(
                                announcement.message,
                                i18nContext
                              )}`
                            : resolveAbsoluteUrlInParagraph(
                                announcement.message,
                                i18nContext
                              ),
                          {
                            ADD_ATTR: ["target"],
                          }
                        ),
                      }}
                    />
                  </MobileContent>
                </div>
              ))}
            </Slider>
          </MessageCarousel>
        )}
        <CloseButton>
          {visibleClose && <NamedIcon icon="close" onClick={handleClose} />}
        </CloseButton>
      </Wrapper>
    </Container>
  );
};

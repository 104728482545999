import * as C from "@components";
import { NoSsr } from "@material-ui/core";
import { PublicConfigurations } from "@types";
import { BreadcrumbListProps } from "components/Breadcrumbs/Breadcrumbs.interface";
import CookieBanner from "components/CookieBanner";
import PageNotFound from "components/ErrorPage";
import { HeaderSchema, SchemaTypes } from "components/HeaderSchema";
import Splash, { SplashData } from "components/Splash";
import { ToastNotification } from "components/ToastNotification";
import {
  additionalContentTypeTemplates,
  siteSettingsReferences,
} from "constants/contentTypeTemplates";
import { PageStorage } from "constants/pages.constants";
import AppContext from "context/AppContext";
import {
  EncryptedGetServerSideProps,
  withEncryptionServerSide,
} from "context/AppEncryptionContext";
import { BaseStorageProvider } from "context/BaseStorageContext";
import { EnquiryProvider } from "context/EnquiryContext";
import { ModalLeadFormProvider } from "context/ModalLeadFormContext";
import { ModalPageNotFoundProvider } from "context/ModalPageNotFoundContext";
import I18nContext, {
  getSEOFromComponents,
  I18nContextData,
} from "i18n/context/LanguageContext";
import { Language, LanguageCode } from "i18n/types";
import { getInitialLocale } from "i18n/utils";
import isEmpty from "lodash/isEmpty";
import type { NextPage } from "next";
import { useRouter } from "next/router";
import { setCookie } from "nookies";
import {
  getConfiguration,
  setConfiguration,
} from "page-services/configuration.service";
import {
  getAnnouncements,
  getSiteSettings,
  getSplashData,
  getStackData,
} from "page-services/helper.service";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  getBreadcrumbPaths,
  getPageLayoutDataByURL,
} from "services/allPage.service";
import {
  BlogData,
  generateSchema,
  getBlogSchemaFromArticleDetailPage,
  SchemaType,
} from "services/schema.service";
import { Announcement } from "types/Annoucement";
import { ErrorPages } from "types/ErrorPage.interface";
import { getComponentConfigs } from "utils/componentConfigs";
import gtmUtils from "utils/gtm";
import { generateUniqSerial } from "utils/uid";

interface Props {
  cookieId: string;
  data: any;
  currentLanguage: Language;
  defaultLanguage: Language;
  supportedLanguages: Record<LanguageCode, Language>;
  stackName: string;
  siteSettings: {
    cookies: {
      cta_text: string;
      description: string;
      expiry_days?: number;
    };
    error_pages: ErrorPages;
    font_family: string;
    font_face: string;
    schema?: {
      publisher_name: string;
      publisher_logo: string;
    };
  };
  announcements: Announcement[];
  splash: SplashData;
  publicConfigurations: PublicConfigurations;
  breadcrumbs: BreadcrumbListProps[];
  locale?: any;
  numberOfLang?: number;
}

type ComponentName = keyof typeof C;

const Page: NextPage<Props> = (props: Props) => {
  const {
    data: components,
    siteSettings,
    announcements,
    splash,
    cookieId,
    breadcrumbs,
    locale,
    numberOfLang,
  } = props;

  const [language, setLanguage] = useState<Language>(props.currentLanguage);
  const [headerVisible, setHeaderVisible] = useState<boolean>(false);
  const [countPageView, setCountPageView] = useState<number>(0);
  const [blogSchema, setBlogSchema] = useState<SchemaTypes[] | null>(null);
  const changeLanguage = useCallback(
    (locale: string) => {
      const language = props.supportedLanguages[locale];
      setLanguage(language);
      document
        ?.querySelector("body")
        ?.classList.toggle(`lang-${language?.languageCode}`);
    },
    [props.supportedLanguages]
  );
  const router = useRouter();

  const componentConfigs = useMemo(() => {
    return getComponentConfigs(components, props.publicConfigurations);
  }, [components, props.publicConfigurations]);

  useEffect(() => {
    if (router.query.market && router.query.env) {
      const { market, env, ...rest } = router.query;
      router.replace(
        {
          pathname: router.pathname,
          query: rest,
        },
        undefined,
        { shallow: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCookie(null, "lang", language.languageCode, {
      maxAge: 30 * 24 * 60 * 60,
      path: "/",
    });
  }, [language?.languageCode]);

  const i18nContextData: I18nContextData = useMemo(
    () => ({
      language,
      supportedLanguages: props.supportedLanguages,
      defaultLanguage: props.defaultLanguage,
      changeLanguage: changeLanguage,
      countryStackName: props.stackName,
      countryCode: props.publicConfigurations?.publicCountryCode || "",
      siteSettings: siteSettings,
      seo: getSEOFromComponents(components),
      componentConfigs: componentConfigs,
      breadcrumbs: breadcrumbs,
    }),
    [
      language,
      props.supportedLanguages,
      props.defaultLanguage,
      props.stackName,
      props.publicConfigurations?.publicCountryCode,
      changeLanguage,
      siteSettings,
      components,
      componentConfigs,
      breadcrumbs,
    ]
  );

  const getBlogSchema = useCallback((): Promise<SchemaTypes[]> | null => {
    const data: BlogData | null = getBlogSchemaFromArticleDetailPage(
      components,
      i18nContextData,
      props.publicConfigurations?.publicRootUrl || "",
      false
    );

    if (!data) {
      return null;
    }

    return generateSchema(data, SchemaType.BLOG);
  }, []);

  useEffect(() => {
    const generateBlogSchema = async () => {
      try {
        const result: SchemaTypes[] | null = await getBlogSchema();

        if (!!result) {
          setBlogSchema(result);
        }
      } catch (error) {
        console.error("Error fetching blog schema:", error);
      }
    };

    generateBlogSchema();
  }, []);

  setCookie(null, "cookieId", cookieId, {
    maxAge: 30 * 24 * 60 * 60,
    path: "/",
  });

  useEffect(() => {
    if (countPageView === 0) {
      setCountPageView(1);
      gtmUtils.pageView(i18nContextData.componentConfigs.gaPageData);
      if (
        Object.keys(i18nContextData.componentConfigs.gaProductMetadata).length >
          0 &&
        i18nContextData.componentConfigs.gaProductMetadata.item_list_name !=
          "landing" &&
        i18nContextData.componentConfigs.gaProductMetadata.item_name
      ) {
        gtmUtils.viewItem(i18nContextData.componentConfigs.gaProductMetadata);
      }
    }
  }, [countPageView]);

  if (!components?.layout?.length) {
    return (
      <I18nContext.Provider value={i18nContextData}>
        <PageNotFound
          data={siteSettings.error_pages}
          errorCode={404}
          locale={locale}
          numberOfLang={numberOfLang || 1}
        />
      </I18nContext.Provider>
    );
  }

  const isHeaderPresent = () => {
    return components?.layout?.some(
      (layout: any) =>
        layout?.nameComponent === "CSHeader" && !isEmpty(layout?.dataComponent)
    );
  };

  return (
    <NoSsr>
      <I18nContext.Provider value={i18nContextData}>
        <AppContext.Provider
          value={{ publicConfigurations: props.publicConfigurations }}
        >
          <BaseStorageProvider storageKey={PageStorage.BaseStorage} timeoutDuration={30 * 60 * 1000}>
            <EnquiryProvider value={props}>
                <ModalPageNotFoundProvider>
                  <ModalLeadFormProvider value={props}>
                    {!isEmpty(announcements) && isHeaderPresent() && (
                      <ToastNotification announcements={announcements} />
                    )}
                    <HeaderSchema data={blogSchema || []} />
                    <div>
                      {components?.layout?.length > 0 &&
                        components?.layout
                          ?.filter((x: any) => !!x)
                          .map((c: any, index: number) => {
                            const name: ComponentName =
                              c.nameComponent as ComponentName;

                            const Component = C[name] as React.FC<any>;

                            if (!Component) {
                              return null;
                            }

                            if (name === "HeaderSchema") {
                              return (
                                <Component
                                  key={`component-${name}-${index}`}
                                  data={{
                                    ...c.dataComponent,
                                  }}
                                />
                              );
                            }

                            return (
                              <Component
                                key={`component-${name}-${index}`}
                                data={{
                                  ...c.dataComponent,
                                  onVisibleChange: setHeaderVisible,
                                  headerVisible,
                                  publicConfigurations: props.publicConfigurations,
                                  index,
                                  pageUid: components?.uid,
                                }}
                              />
                            );
                          })}
                    </div>
                    {!isEmpty(siteSettings?.cookies) && (
                      <CookieBanner data={siteSettings?.cookies} />
                    )}
                    <Splash data={splash} />
                  </ModalLeadFormProvider>
                </ModalPageNotFoundProvider>
            </EnquiryProvider>
          </BaseStorageProvider>
        </AppContext.Provider>
      </I18nContext.Provider>
    </NoSsr>
  );
};

export const getServerSideProps: EncryptedGetServerSideProps<Props> =
  withEncryptionServerSide(async (ctx) => {
    const { params, query, req } = ctx;

    const market = query.market ? (query.market as string) : undefined;
    const env = query.env ? (query.env as string) : undefined;

    console.log({ market, env });

    const cookieId: string = req.cookies.cookieId || generateUniqSerial();
    const status = await setConfiguration(cookieId, market, env);
    if (status !== 200) {
      return {
        redirect: {
          permanent: false,
          destination: `/${market || getInitialLocale()}/${status}`,
        },
      };
    }

    const languageCode = params?.lang as string;

    const stackData = await getStackData();
    const isValidLang = Object.values(stackData.languages).find(
      (language: any) => language.languageCode === languageCode
    );
    if (!isValidLang) {
      return {
        notFound: true,
      };
    }
    const currentLanguage: Language =
      stackData.languages[languageCode] || stackData.defaultLanguage;

    const publicConfigurations: PublicConfigurations = {
      publicRootUrl: getConfiguration(
        "NEXT_PUBLIC_ROOT_URL",
        process.env.NEXT_PUBLIC_ROOT_URL
      ),
      publicCountryCode: getConfiguration(
        "NEXT_PUBLIC_COUNTRY_CODE",
        process.env.NEXT_PUBLIC_COUNTRY_CODE
      ),
      apiAuthBase64: getConfiguration(
        "NEXT_PUBLIC_API_AUTH_BASE64",
        process.env.NEXT_PUBLIC_API_AUTH_BASE64
      ),
      googleMapApiKey: getConfiguration(
        "NEXT_PUBLIC_GOOGLE_MAP_API_KEY",
        process.env.NEXT_PUBLIC_GOOGLE_MAP_API_KEY
      ),
      dtcCampaignId: getConfiguration(
        "NEXT_PUBLIC_TH_SAVE_LEAD_DTC_CAMPAIGN_ID",
        process.env.NEXT_PUBLIC_TH_SAVE_LEAD_DTC_CAMPAIGN_ID
      ),
      publicApiUrl: getConfiguration(
        "NEXT_PUBLIC_API_URL",
        process.env.NEXT_PUBLIC_API_URL
      ),
      fCounterUrl: getConfiguration(
        "NEXT_PUBLIC_FCOUNTER_URL",
        process.env.NEXT_PUBLIC_FCOUNTER_URL
      ),
      fCounterToken: getConfiguration(
        "NEXT_PUBLIC_FCOUNTER_TOKEN",
        process.env.NEXT_PUBLIC_FCOUNTER_TOKEN
      ),
      csBranchName: getConfiguration(
        "CONTENTSTACK_BRANCH",
        process.env.CONTENTSTACK_BRANCH || "main"
      ),
      flexibleFormApiUrl: getConfiguration(
        "NEXT_PUBLIC_FLEXIBLE_FORM_API_URL",
        process.env.NEXT_PUBLIC_FLEXIBLE_FORM_API_URL
      ),
      flexibleFormApiKey: getConfiguration(
        "NEXT_PUBLIC_FLEXIBLE_FORM_API_KEY",
        process.env.NEXT_PUBLIC_FLEXIBLE_FORM_API_KEY
      ),
    };
    try {
      let breadcrumbs: any = [];
      const slug = ((params?.slug || [""]) as string[]).join("/");

      const numberOfLang = Object.keys(stackData.languages).length;
      const locale = currentLanguage.locale;

      const [data, siteSettings, announcements, splash] = await Promise.all([
        getPageLayoutDataByURL(slug, locale, numberOfLang),

        getSiteSettings(
          currentLanguage.locale,
          stackData,
          siteSettingsReferences()
        ),

        additionalContentTypeTemplates().includes("oneweb_announcement") &&
          getAnnouncements(currentLanguage.locale),

        additionalContentTypeTemplates().includes("splash") &&
          getSplashData(currentLanguage.locale),
      ]);

      if (siteSettings.breadcrumb_settings?.is_enabled) {
        breadcrumbs = await getBreadcrumbPaths(slug, currentLanguage.locale);
      }

      return {
        props: {
          cookieId,
          data,
          currentLanguage,
          defaultLanguage: stackData.defaultLanguage,
          supportedLanguages: stackData.languages,
          stackName: stackData.stackName,
          siteSettings,
          announcements,
          splash,
          publicConfigurations,
          breadcrumbs,
          locale,
          numberOfLang,
        },
      };
    } catch (error) {
      console.log({ error });

      return {
        notFound: true,
      };
    }
  });

export default Page;
